body {

}

.mygreen {
    color: #336666;
}

h1 {
    color: #336666;
}

.fsize {
    font-size: 14px;
    text-align: justify;
}

.imgthumb {
    width: 150px;
    margin-left: 35px;
    margin-top: 20px;
}

p {
    padding: 0px;
    margin: 0px;
}

.heading {
    color: #336666;
    font-size: 18px;
    font-weight: bold;
    border-bottom: thin solid #336666;
}

.subheading {
    color: #336666;
    font-size: 14px;
}

.about {
    text-align: justify;
}

.about p {
    padding: 5px;
    font-size: 14px;
}